// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---gatsby-theme-nurofen-src-templates-advanced-children-hub-listing-page-advanced-children-hub-listing-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/AdvancedChildrenHubListingPage/AdvancedChildrenHubListingPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-advanced-children-hub-listing-page-advanced-children-hub-listing-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-article-listing-page-article-listing-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ArticleListingPage/ArticleListingPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-article-listing-page-article-listing-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-campaign-page-plain-campaign-page-plain-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/CampaignPagePlain/CampaignPagePlain.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-campaign-page-plain-campaign-page-plain-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-children-dosage-children-dosage-information-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ChildrenDosage/ChildrenDosageInformation.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-children-dosage-children-dosage-information-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-children-hub-page-children-hub-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ChildrenHubPage/ChildrenHubPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-children-hub-page-children-hub-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-community-page-community-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/CommunityPage/CommunityPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-community-page-community-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-contact-us-page-contact-us-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ContactUsPage/ContactUsPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-contact-us-page-contact-us-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-covid-page-covid-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/CovidPage/CovidPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-covid-page-covid-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-error-404-page-error-404-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/Error404Page/Error404Page.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-error-404-page-error-404-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-home-page-home-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/HomePage/HomePage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-home-page-home-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-lds-page-lds-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/LdsPage/LdsPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-lds-page-lds-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-product-list-page-product-list-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ProductListPage/ProductListPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-product-list-page-product-list-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-product-page-product-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/ProductPage/ProductPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-product-page-product-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-search-page-search-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/SearchPage/SearchPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-search-page-search-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-sitemap-page-sitemap-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/SitemapPage/SitemapPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-sitemap-page-sitemap-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-symptoms-page-symptoms-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/SymptomsPage/SymptomsPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-symptoms-page-symptoms-page-tsx" */),
  "component---gatsby-theme-nurofen-src-templates-why-nurofen-page-why-nurofen-page-tsx": () => import("./../../../../gatsby-theme-nurofen/src/templates/WhyNurofenPage/WhyNurofenPage.tsx" /* webpackChunkName: "component---gatsby-theme-nurofen-src-templates-why-nurofen-page-why-nurofen-page-tsx" */),
  "component---src-gatsby-theme-nurofen-templates-advanced-children-hub-article-page-advanced-children-hub-article-page-tsx": () => import("./../../../src/gatsby-theme-nurofen/templates/AdvancedChildrenHubArticlePage/AdvancedChildrenHubArticlePage.tsx" /* webpackChunkName: "component---src-gatsby-theme-nurofen-templates-advanced-children-hub-article-page-advanced-children-hub-article-page-tsx" */),
  "component---src-gatsby-theme-nurofen-templates-article-page-article-page-tsx": () => import("./../../../src/gatsby-theme-nurofen/templates/ArticlePage/ArticlePage.tsx" /* webpackChunkName: "component---src-gatsby-theme-nurofen-templates-article-page-article-page-tsx" */),
  "component---src-gatsby-theme-nurofen-templates-campaign-page-star-trek-campaign-page-star-trek-tsx": () => import("./../../../src/gatsby-theme-nurofen/templates/CampaignPageStarTrek/CampaignPageStarTrek.tsx" /* webpackChunkName: "component---src-gatsby-theme-nurofen-templates-campaign-page-star-trek-campaign-page-star-trek-tsx" */)
}

